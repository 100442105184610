<template>
    <div class="max-w-lg mx-auto flex flex-col items-center justify-center min-h-screen">
        <!-- Hidden form. -->
        <form
            v-if="!error"
            class="hidden"
            @submit.prevent="signUpActivateCode"
        >
            <button
                type="submit"
                ref="submitBtn"
            ></button>
        </form>

        <!-- Spinner gif -->
        <img
            v-if="!error"
            :src="require('@/assets/images/spiner.gif')"
            alt="Loading gif"
        >

        <!-- Error message -->
        <div v-if="error">
            <div class="mb-36">
                <h1 class="h1 text-purple-700 mb-10">{{$t('generalErrorTitle')}}</h1>
                <p class="small text-red-500">{{error}}</p>
            </div>

            <div class="flex items-center justify-center mb-36">
                <a
                    :href="link"
                    target="_blank"
                    class="button bg-purple-500 text-white hover:bg-purple-600 flex items-center justify-center"
                >
                    {{ $t('successMessageButtonText') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'SignUpActivate',

    data () {
        return {
            link: process.env.VUE_APP_WEBSITE
        }
    },

    computed: {
        /** Get vuex state. */
        ...mapGetters(['error', 'activationCode', 'token', 'userType'])
    },

    methods: {
        /** Get vuex action. */
        ...mapActions(['signupActivateCode']),

        /** Save activation code form. */
        signUpActivateCode () {
            this.signupActivateCode({ activation_code: this.$route.query.code })
        }
    },

    mounted () {
        /** Submit form on mounted lifecycle. */
        this.$refs.submitBtn.click()
    }
}
</script>
